import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { nfcQueries } from "./queries";
import {
  useClaimBySigningUp,
  useRequestClaimCodeMutation,
  useRequestVerifyCode,
} from "./mutations";
import { toast } from "vue3-toastify";
import { nfcCampaignVariants } from "./data";

const currentNfcId = computed(() => {
  const route = useRoute();
  return route.params.id as string;
});

export const useNfc = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery(nfcQueries.detail(currentNfcId));

  const prefetch = async () => {
    try {
      const data = await queryClient.fetchQuery(
        nfcQueries.detail(currentNfcId),
      );

      // console.log(data);
    } catch (e: any) {
      // if 404 error, then navigateTo('/')
      navigateTo("/");
    }
  };

  const currentVariant = computed(() => {
    return nfcCampaignVariants[data.value!.nfcData!.campaignId];
  });

  const currentMetrics = computed(() => {
    const metrics =
      data.value!.nfcData!.ownMetrics[data.value!.nfcData!.campaignId];
    const additional =
      data.value!.nfcData!.additionalMetrics[data.value!.nfcData!.campaignId];

    const total = {
      impact: metrics.impact + additional.impact,
      donated: metrics.donated + additional.donated,
    }

    return {
      ...metrics,
      additional,
      total
    };
  });

  return {
    data,
    isLoading,
    error,
    currentNfcId,
    prefetch,
    currentVariant,
    currentMetrics,
  };
};

type ClaimNfcExistingUserStep = "request-code" | "verify-code" | "success-step";
const claimByExistingUserValues = reactive({
  email: "",
  code: "",
});
export const useClaimNfcExistingUser = () => {
  const route = useRoute();
  const router = useRouter();
  const { mutate: mutateClaimCode, isPending: isClaimCodePending } =
    useRequestClaimCodeMutation();

  const { mutate: mutateVerifyCode, isPending: isVerifyCodePending } =
    useRequestVerifyCode();

  const verifyCode = async (params: { code: string }) => {
    mutateVerifyCode(
      {
        ...params,
        nfcId: currentNfcId.value,
        email: claimByExistingUserValues.email,
      },
      {
        onSuccess: () => {
          currentStep.value = "success-step";
        },
        onError: (error) => {
          toast.error("Invalid or expired code");
        },
      },
    );
  };

  const requestLoginCode = async (params: { email: string }) => {
    mutateClaimCode(
      {
        ...params,
        nfcId: currentNfcId.value,
      },
      {
        onSuccess: () => {
          claimByExistingUserValues.email = params.email;
          currentStep.value = "verify-code";
        },
        onError: (error) => {
          if (error instanceof Error && error.message === "MEMBER_NOT_FOUND") {
            toast.warning("Member not found");
          } else if (
            error instanceof Error &&
            error.message === "Password token already generated"
          ) {
            toast.error(error.message);
          } else {
            toast.error("An error occurred. Please try again.");
          }
        },
      },
    );
  };

  const isMutating = computed(() => {
    return isClaimCodePending.value || isVerifyCodePending.value;
  });

  const currentStep = computed({
    get: () => {
      if (!route.query.step) return "request-code";

      return route.query.step as ClaimNfcExistingUserStep;
    },
    set: (value) => {
      router.push({
        query: {
          ...route.query,
          step: value,
        },
      });
    },
  });

  const init = () => {
    claimByExistingUserValues.email = "";
    claimByExistingUserValues.code = "";
  };

  return {
    currentStep,
    values: claimByExistingUserValues,
    isMutating,
    verifyCode,
    requestLoginCode,
    init,
  };
};

const hasBeenClaimedSuccessfully = ref(false);
export const useClaimNfcNewUser = () => {
  const { mutate, isPending } = useClaimBySigningUp();

  const claimNfc = async (params: {
    email: string;
    firstName: string;
    lastName: string;
    postalCode: string;
  }) => {
    mutate(
      {
        ...params,
        nfcId: currentNfcId.value,
      },
      {
        onSuccess: () => {
          hasBeenClaimedSuccessfully.value = true;
        },
        onError: (error) => {
          if (
            error instanceof Error &&
            error.message === "Member already exists"
          ) {
            toast.warning(
              "Member already exists. Please try logging in instead.",
            );
          }

          if (
            error instanceof Error &&
            error.message === "Invalid postal code"
          ) {
            toast.error("Invalid postal code");
          }

          if (
            error instanceof Error &&
            error.message === "Error creating member"
          ) {
            toast.error("Error creating member");
          }

          toast.error("An error occurred. Please try again.");
        },
      },
    );
  };

  return {
    isMutating: isPending,
    hasBeenClaimedSuccessfully,
    claimNfc,
  };
};

export const useNfcDonate = () => {
  const router = useRouter();
  const { data } = useQuery(nfcQueries.detail(currentNfcId));

  const donationAmount = ref(10);
  const impactAmount = computed({
    get: () => {
      return parseFloat(
        (donationAmount.value * data.value!.nfcData!.dollarValue).toPrecision(
          12,
        ),
      );
    },
    set: (value: number) => {
      donationAmount.value = value / data.value!.nfcData!.dollarValue;
    },
  });

  const goToPayment = () => {
    if (donationAmount.value < 1) {
      toast.error("Please donate at least $1");
      return;
    }

    router.push({
      path: "/sign-up",
      query: {
        campaignId: data.value!.nfcData!.campaignId,
        amount: donationAmount.value,
        nfcId: data.value!.nfcData!.id,
        step: "checkout",
      },
    });
  };

  return {
    data,
    donationAmount,
    impactAmount,
    goToPayment,
  };
};
